.loader {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99999999;
    top: 0;
    left: 0;
    .container-loader{
        width: 100%;
        height: 100%;
        position: relative;
    }
    .container-box{
        left: 45%;
        top: 40%;
        position: absolute;
    }


    .box1,
    .box2,
    .box3 {
        box-sizing: border-box;
        position: absolute;
        display: block;
    }

    .box1 {
        border: 16px solid $rose;
        width: 112px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
        animation: abox1 4s forwards ease-in-out infinite;
    }

    .box2 {
        border: 16px solid $bleu;
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
        animation: abox2 4s forwards ease-in-out infinite;
    }

    .box3 {
        border: 16px solid $jaune;
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
        animation: abox3 4s forwards ease-in-out infinite;
    }
}


@keyframes abox1 {
    0% {
        width: 112px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }

    12.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }

    25% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }

    37.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }

    50% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }

    62.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }

    75% {
        width: 48px;
        height: 112px;
        margin-top: 0px;
        margin-left: 0px;
    }

    87.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }

    100% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }
}

@keyframes abox2 {
    0% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }

    12.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }

    25% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }

    37.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }

    50% {
        width: 112px;
        height: 48px;
        margin-top: 0px;
        margin-left: 0px;
    }

    62.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }

    75% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }

    87.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }

    100% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }
}

@keyframes abox3 {
    0% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }

    12.5% {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-left: 64px;
    }

    25% {
        width: 48px;
        height: 112px;
        margin-top: 0px;
        margin-left: 64px;
    }

    37.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }

    50% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }

    62.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }

    75% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }

    87.5% {
        width: 48px;
        height: 48px;
        margin-top: 64px;
        margin-left: 64px;
    }

    100% {
        width: 112px;
        height: 48px;
        margin-top: 64px;
        margin-left: 0px;
    }
}

// NumberLoader

.Numberloader {
    border: 4px solid rgba(0, 0, 0, .1);
    border-left-color: transparent;
    border-radius: 50%;
  }
  
  .Numberloader {
    border: 3px solid rgba(0, 0, 0, .1);
    border-left-color: transparent;
    width: 20px;
    height: 20px;
    animation: spin89345 1s linear infinite;
  }
  
  @keyframes spin89345 {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }